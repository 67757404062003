
.icon-klipfolio {
  background-image: url("assets/img/icons/common/klips-favicon.png");
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
}
.icon-twilio {
  background-image: url("assets/img/icons/common/twilio-icon.svg");
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
}

.Select-menu-outer {
  top: auto;
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.Select-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.Select-option:last-child {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.Select.is-open > .Select-control {
  border-top-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

i.bs-disabled {
  color: gray !important;
  pointer-events: none;
}